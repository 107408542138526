@import "../shared-styles/colors.scss";
@import "../shared-styles/settings.scss";

@mixin footerColumn() {
    padding: 0 15px;
    box-sizing: border-box;
    float: left;
}

@mixin header() {
    width: 100%;
    margin: 0 0 8px;
}

@mixin headerSlotted() {
    font-family: $footerColumnTitleFont !important;
    text-transform: uppercase !important;
    color: $navy !important;
    font-size: 14px !important;
    line-height: 17px !important;
    border-bottom: 1px solid $gray !important;
    padding-bottom: 3px !important;
    font-weight: $footerHeaderFontWeight !important;
    width: 100%;
    display: inline-block;
    max-width: $footerColumnHeaderMaxWidth;
}

@mixin mobileHeaderSlotted() {
    font-size: 12px !important;
    max-width: $footerColumnHeaderMaxWidth;
}

@mixin allContent() {
    max-width: 100%;
}

@mixin contentNotButton() {
    font-family: $footerContentFont !important;
    font-size: $footerContentFontSize !important;
    line-height: $footerContentLineHeight !important;
    font-weight: $footerContentFontWeight !important;
    color: $footerContentColor !important;
    display: block;
    margin: 0;
}

@mixin mobileContentNotButton() {
    font-size: $mobileFooterContentFontSize !important;
}

@mixin defaultContentLink() {
    text-decoration: none !important;
    outline: none !important;
    color: $royal !important;
    font-size: $footerContentFontSize;
    height: $footerLinkLineHeight;
}

@mixin defaultContentDivOrParagraph() {
    text-decoration: none !important;
    outline: none !important;
    font-size: $footerContentFontSize;
    min-height: $footerLinkLineHeight;
}

@mixin mobileContentLink {
    font-size: $mobileFooterContentFontSize;
    height: $mobileFooterLinkLineHeight;
}

@mixin defaultContentLinkHover() {
    color: $navy !important;
}

@mixin defaultContentLinkFocus() {
    outline: solid 2px $royal !important;
}
