// blues
$navy: #002E5D;
$xlightblue: #C3DDF9;
$royal: #0057B8;
$hoverBlue: #32538D;

// grays
$darkgray: #141414;
$medgray: #666;
$userNameGray: #D8D8D8;
$gray: #C5C5C5;
$lightgray: #EDEDED;
$white: #FFF;
$white88: rgba(255, 255, 255, 0.88);