@import "../shared-styles/colors.scss";
@import "../shared-styles/settings.scss";
@import "../shared-styles/nav.scss";

@mixin menuGeneral() {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  // opacity used to be set here, but now it is an option.
}

@mixin outerNav() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 35px;
  position: relative;
  flex: 1;
  flex-basis: 100%;
}

@mixin navItemSlotted() {
  @include navItem();
  flex: 1;
  flex-basis: auto;
  text-transform: uppercase;
}

@mixin navItemsSlottedSetWidth() {
  min-width: 200px;
  flex: 0;
  padding: 0 16px;
  flex-basis: auto;
}

@mixin moreMenu() {
  display: none;
  position: relative;
  height: 35px;
  width: 16.66%;
  overflow: visible !important;
  padding: 0 !important;
}

@mixin moreMenuVisible() {
  display: block;
}

@mixin menuMoreExpanded() {
  background: $menuActive;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.75);
}

@mixin menuMoreItems() {
  display: block;
}

@mixin menuFixedOverlay() {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@mixin moreLink() {
  @include navItem();
  position: relative;
  flex: 1;
  display: block;
}

@mixin moreLinkSvg() {
  background-image: url('https://cdn.byu.edu/shared-icons/latest/fontawesome/arrow-navy.svg');
  height: 4px;
  width: 7px;
  display: inline-block;
  background-size: 100%;
  margin-bottom: 2px;
  margin-left: 4px;
}

@mixin moreLinkHoverSvg() {
  background-image: url('https://cdn.byu.edu/shared-icons/latest/fontawesome/arrow-white.svg');
  
}

@mixin moreItems() {
  display: none;
  position: absolute;
  top: 35px;
  right: 0;
  min-width: 100%;
  max-width: 250px;
  z-index: 2;
  background: white;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.75);
}

@mixin moreItemsSlotted() {
  width: 100%;
  @include navItem();
  display: block;
  text-align: left !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

@mixin moreItemsSlottedHover() {
  color: #ffffff !important;
}

/* MOBILE */

@mixin mobileOuterNav() {
  display: block;
  height: auto;
}

@mixin mobileMenuMore() {
  display: none !important;
}

@mixin mobileMenuSlotted() {
  display: block !important;
  text-align: left !important;
  padding: 18px 18px 18px 25px !important;
  height: auto !important;
  width: 100%;
  max-width: 100%;
  position: static;
  box-sizing: border-box;
  line-height: 12px !important;
  font-size: 12px !important;
}

@mixin mobileMenuMoreMenuSlotted() {
  padding: 0 !important;
}

@mixin mobileMenuMoreMenu() {
  display: block;
  width: 100%;
  box-shadow: none;
  position: static;
  height: auto;
}

@mixin mobileMenuMoreItems() {
  display: block;
  box-shadow: none;
  padding: 0;
  position: static !important;
}

@mixin mobileMenuMoreItemsSlotted() {
  display: block !important;
  text-align: left !important;
  padding: 18px 25px !important;
  height: auto !important;
  width: 100%;
  position: static;
  box-sizing: border-box;
  line-height: 12px !important;
}

@mixin mobileMenuMoreExpanded() {
  box-shadow: none;
}

@mixin mobileMenuFixedOverlay() {
  display: none !important;
}