@import "byu-footer-action-button-common";

//byu-footer-action-button:not(:defined) {
byu-footer-action-button:not(.byu-component-rendered) {
    @include footerActionButton();
}

//byu-footer-action-button:not(:defined) > * {
byu-footer-action-button:not(.byu-component-rendered) > * {
    @include footerActionButtonSlotted();
}

//byu-footer-action-button:not(:defined) a {
byu-footer-action-button:not(.byu-component-rendered) a {
    @include footerActionButtonSlottedLink();
}

byu-footer-action-button:not(.byu-component-rendered) a:focus {
    @include footerActionButtonSlottedLinkFocus();
}
