@import "colors.scss";
@import "settings.scss";

@mixin navItemHover() {
  background: $hoverBlue !important;
  color: #ffffff !important;
}

@mixin navItemSelected() {
  background: $lightgray !important;
}

//@mixin navItemLongLink() {
//  max-width: 300px;
//  flex: 2;
//}
//
//@mixin navItemExtraLongLink() {
//  max-width: 400px;
//  flex: 3;
//}

@mixin navItem() {
  text-decoration: none !important;
  font-size: 14px!important;
  font-family: $menuFont !important;
  font-weight: $menuFontWeight!important;
  color: $navy !important;
  height: $menuHeight !important;
  display: inline-block;
  box-sizing: border-box;
  text-align: center !important;
  padding: 0 6px !important;
  line-height: $menuHeight !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  transition: background 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.3px;

  &:hover {
    @include navItemHover();
  }

  &.selected {
    @include navItemSelected();
  }

  &.active {
    @include navItemSelected();
  }

  //&.long-link {
  //  @include navItemLongLink();
  //}
  //
  //&.extra-long-link {
  //  @include navItemExtraLongLink();
  //}
}

@mixin dropdownNavItem() {
  @include navItem();
  display: block;
  //width: 100%;
  box-sizing: border-box;
  padding: 18px 33px !important;
  line-height: 12px;
  text-align: left !important;
  height: auto !important;
}