@import "../shared-styles/colors.scss";

@mixin socialMain() {
    display: flex;
    flex-direction: row;
}

//noinspection CssOptimizeSimilarProperties
@mixin allSocialIcons() {
    height: 28px;
    width: 28px;
    display: block;

    color: transparent!important;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: content-box;
    background-position: center;

    margin: 8px 8px 8px 0 !important;
}

//We use obfuscated image names here to get around some common ad blocking lists.
@mixin facebook() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/fa.svg');
}

@mixin twitter() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/tw.svg');
}

@mixin instagram() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/in.svg');
}

@mixin youtube() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/yo.svg');
}

@mixin pinterest() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/pi.svg');
}

@mixin google-plus() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/gp.svg');
}

@mixin linkedin() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/li.svg');
}

@mixin rss() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/rs.svg');
}

@mixin snapchat() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/sn.svg');
}

@mixin podcast() {
    background-image: url('https://cdn.byu.edu/shared-icons/latest/social/obfuscated/po.svg');
}
