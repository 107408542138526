@import "shared-styles/colors";
@import "shared-styles/nav";
@import "https://cdn.byu.edu/theme-fonts/1.x.x/ringside/fonts.css";
// import individual component extras files
@import "byu-footer/byu-footer-extras";
@import "byu-footer-action-button/byu-footer-action-button-extras";
@import "byu-footer-column/byu-footer-column-extras";
@import "byu-header/byu-header-extras";
@import "byu-menu/byu-menu-extras";
@import "byu-search/byu-search-extras";
@import "byu-social-media-links/byu-social-media-links-extras";
@import "byu-user-info/byu-user-info-extras";

// this isn't the place for general styling. Please include further styling in the 2017-styles repo.
body {
  margin: 0;
}