
@import "byu-footer-common";

/****** FOUC STYLING ******/

byu-footer:not(.byu-component-rendered) {
  background: $lightgray;
  width: 100%;
  font-family: sans-serif;
  font-weight: 600;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: space-around;
  display: grid;
  grid-template-columns: 1fr repeat(4, 300px) 1fr;
  grid-template-rows: 1fr 76px;

  &:empty {
    min-height: 0;
    padding: 0;
  }
}

byu-footer:not(.byu-component-rendered):after {
  background: url(https://cdn.byu.edu/shared-icons/latest/logos/BYU-Footer-Ringside.svg) center 11px no-repeat $navy;
  background-size: 390px 40px;
  height: 76px;
  width: 100vw;
  content: ' ';
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 2/3;
  grid-column: 1/7;
}

byu-footer:not(.byu-component-rendered) > *:nth-child(1) {
  grid-row: 1/2;
  grid-column: 2/3;
}

byu-footer:not(.byu-component-rendered) > *:nth-child(2) {
  grid-row: 1/2;
  grid-column: 3/4;
}

byu-footer:not(.byu-component-rendered) > *:nth-child(3) {
  grid-row: 1/2;
  grid-column: 4/5;
}

byu-footer:not(.byu-component-rendered) > *:nth-child(4) {
  grid-row: 1/2;
  grid-column: 5/6;
}


/****** RESPONSIVE FOUC STYLING ******/

@media (max-width: 1024px) {

  byu-footer:not(.byu-component-rendered) {
    display: grid;
    grid-template-columns: 1fr repeat(2, 300px) 1fr;
    grid-template-rows: 1fr 1fr 76px;
  }

  byu-footer:not(.byu-component-rendered):after {
    grid-row: 3/4;
    grid-column: 1/5;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(1) {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(2) {
    grid-row: 1/2;
    grid-column: 3/4;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(3) {
    grid-row: 2/3;
    grid-column: 2/3;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(4) {
    grid-row: 2/3;
    grid-column: 3/4;
  }

}

@media (max-width: 599px) {

  byu-footer:not(.byu-component-rendered) {
    display: grid;
    grid-template-columns: 1fr 300px 1fr;
    grid-template-rows: repeat(4, 1fr) 76px;
  }

  byu-footer:not(.byu-component-rendered):after {
    grid-row: 5/6;
    grid-column: 1/4;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(1) {
    grid-row: 1/2;
    grid-column: 2/3;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(2) {
    grid-row: 2/3;
    grid-column: 2/3;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(3) {
    grid-row: 3/4;
    grid-column: 2/3;
  }

  byu-footer:not(.byu-component-rendered) > *:nth-child(4) {
    grid-row: 4/5;
    grid-column: 2/3;
  }

}