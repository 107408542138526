@import "colors.scss";

/* --- Color variable names are all lower case. Other variables are camel-case --- */

/* -- breakpoint settings ----- */
$mobile_sidebar_width: 20%;
$mobile_main_width: 100% - $mobile_sidebar_width;

/* --- header settings ------- */
$headerHeight: 58px;
$mobileHeaderHeight: 45px;
$siteNameFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;

$byuLogoHeight: 19px;
$mobileByuLogoHeight: 13px;

$siteTitleSize: 24px;
$mobileSiteTitleSize: 17.5px;
$siteSubTitleSize: 13px;
$mobileSiteSubTitleSize: 12px;
$mobileSiteSubtitleLineHeight: 14px;

$pageContentVerticalPadding: 0;
$pageContentHorizontalPadding: 16px;
$pageContentPadding: $pageContentVerticalPadding $pageContentHorizontalPadding;

$headerContentFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$headerContentFontWeight: 400;

/* --- search settings -------- */
$searchPlaceholderColor: $gray;
$searchActiveColor: $navy;
$searchHeight: 27px;
$searchWidth: 41px;

// menu settings
$menuHeight: 35px;
$menuFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$menuFontWeight: 400;
$menuHover: $gray;
$menuActive: $lightgray;

/* ---------  general text settings ----- */



/* ------ footer settings ------------- */


$footerBackground: $lightgray;
$footerContentColor: $darkgray;
$footerColumnTitleFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$footerContentFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$footerHeaderFontWeight: 500;
$footerContentFontWeight: 400;
$footerContentFontSize: 14px;
$footerContentLineHeight: 16px;

$mobileFooterContentFontSize: 12px;

$footerButtonColor: $hoverBlue;
$footerButtonFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;

$footerColumnHeaderMaxWidth: 230px;
$footerLinkLineHeight: 35px;
$mobileFooterLinkLineHeight: 25px;