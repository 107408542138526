@import "byu-header-common";

byu-header {
  z-index: 1;
}

/****** Slot Style Patching ******/
byu-header a[slot="site-title"], byu-header [slot="site-title"] a {
  @include headerTitleText();
  padding: 0;
}

byu-header [slot="actions"] {
  color: $action-text-color;
  a {
    color: $action-text-color;
    text-decoration: none;
    display: block;
    padding: 100px 24px;
    margin: 0 -24px;
  }
  &:hover {
    color: $action-text-hover-color;
    a {
      color: $action-text-hover-color;
    }
  }
}

/****** FOUC STYLING ******/

byu-header:not(.byu-component-rendered) {
  @include headerPrimary();
  flex-wrap: wrap;
  width: 100%;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  color: $white !important;
  padding: 0;
  a {
    color: $white !important;
  }
  [slot="actions"] {
    text-transform: uppercase;
    font-family: 'HCo Ringside Narrow SSm', Helvetica, sans-serif;
    order: 0;
    text-decoration: none;
    background-color: #32538d;
    height: 29px;
    font-weight: 400;
    font-size: 11px;
    padding: 0 24px;
    color: #fff;
    display: flex;
    align-items: center;
    margin: auto 9px auto 10px;
  }
}

byu-header:not(.byu-component-rendered) > [slot="site-title"] {
  @include headerTitles();
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  padding: 0;
  padding-left: 16px;
  position: relative;
  top: -1px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

byu-header:not(.byu-component-rendered) > [slot="site-title"].subtitle {
  display: none;
}

// BYU icon
byu-header:not(.byu-component-rendered) > [slot="site-title"]:before {
  background-image: url(https://cdn.byu.edu/shared-icons/latest/logos/monogram-white.svg);
  height: 19px;
  width: 66px;
  margin: 19.5px 8px 19.5px 0;
  content: ' ';
  background-repeat: no-repeat;
}

//BYU-HEADER FULL-WIDTH ATTRIBUTE

byu-header[full-width] byu-menu:not(.byu-component-rendered) {
  grid-template-columns: repeat(6, 1fr);
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(1) {
  grid-column: 1/2;
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(2) {
  grid-column: 2/3;
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(3) {
  grid-column: 3/4;
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(4) {
  grid-column: 4/5;
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(5) {
  grid-column: 5/6;
}

byu-header[full-width] byu-menu:not(.byu-component-rendered) > *:nth-child(6) {
  grid-column: 6/7;
}

//CONSTRAINED-TOP-BAR ATTRIBUTE

byu-header[constrain-top-bar]:not(.byu-component-rendered) {
  display: grid;
  grid-template-columns: 1fr 645px 555px 1fr;
  grid-template-rows: 58px 35px;
  [slot="actions"] {
    grid-column: 3/4;
    grid-row: 1/2;
    justify-self: flex-start;
  }
  [slot="actions"]:nth-of-type(2) {
    margin-left: 103px;
  }
}

byu-header[constrain-top-bar]:not(.byu-component-rendered) > [slot="site-title"] {
  grid-column: 2/3;
  grid-row: 1/2;
}

byu-header[constrain-top-bar] byu-search:not(.byu-component-rendered) {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: flex-end;
}

byu-header[constrain-top-bar] byu-user-info:not(.byu-component-rendered) {
  grid-column: 3/4;
  grid-row: 1/2;
  justify-self: center;
  margin-right: 50px;
}

byu-header[constrain-top-bar] byu-menu:not(.byu-component-rendered) {
  grid-column: 1/5;
  grid-row: 2/3;
}

/****** RESPONSIVE FOUC STYLING ******/

@media (max-width: 1023px) {

  byu-header:not(.byu-component-rendered) {
    //height: 80px;
    //min-height: 80px;
    //max-height: 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .16);
    height: unset;
    min-height: unset;
    max-height: unset;
    display: block;
    [slot="actions"] {
      display: none;
    }
  }

  byu-header:not(.byu-component-rendered) > [slot="site-title"] {
    font-size: 17.5px !important;
    top: 0;
  }

  // byu icon
  byu-header:not(.byu-component-rendered) > [slot="site-title"]:before {
    height: 13px;
    width: 45px;
    margin: 16px 8px 16px 0;
    top: 0;
  }

  byu-header[constrain-top-bar]:not(.byu-component-rendered) {
    display: block;
  }

}