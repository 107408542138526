@import "byu-user-info-common";

byu-user-info:not(.byu-component-rendered) {
  &.byu-user-info_has_user {
    display: flex;
    align-items: center;

    [slot=logout] {
      display: unset;
      order: 2;
    }

    [slot=login] {
      display: none;
    }

    [slot=logout]:before {
      background-image: url(https://cdn.byu.edu/shared-icons/latest/fontawesome/user-circle-o-white.svg);
      content: "";
      display: inline-flex;
      position: relative;
      height: 14px;
      width: 14px;
      background-size: cover;
      margin: 0 7px;
      top: 2px;
    }

    [slot=user-name] {
      order: 0;
      margin-top: 4px;
    }
  }
  > * {
    text-decoration: none !important;
    font-family: $headerContentFont !important;
    font-weight: $headerContentFontWeight !important;
    text-transform: uppercase !important;
    font-size: 11px !important;
    color: #fff !important;
    letter-spacing: .5px;
  }

  [slot=logout] {
    display: none;
  }

  [slot=user-name] {
    color: #c3ddf9 !important;
  }

  [slot=login]:before {
    background-image: url(https://cdn.byu.edu/shared-icons/latest/fontawesome/user-circle-o-white.svg);
    content: "";
    display: inline-flex;
    position: relative;
    height: 14px;
    width: 14px;
    background-size: cover;
    margin: 0 7px;
    top: 2px;
  }

  /* NON MOBILE */
  &:not([mobile-view]) {
    @include userInfoNotMobile();
    order: 1;
    margin: auto 13px;
    position: relative;
    top: -1px;

    & > * {
      text-decoration: none !important;
      font-size: 11px !important;
      font-family: $headerContentFont !important;
      font-weight: $headerContentFontWeight !important;
      text-transform: uppercase !important;
      color: #fff;
    }

    .mobile {
      @include userInfoNotMobileDisplay();
    }

    img {
      @include userInfoNotMobileImages();
    }
  }

  /****** RESPONSIVE FOUC STYLING ******/

  @media (max-width: 1023px) {
    display: none;
  }
}
