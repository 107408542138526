
@import "../shared-styles/colors.scss";
@import "byu-search-common";

// Helpers for styling search elements.

byu-search input.__byu-search-selected-input {
  @include searchInput();
  @include searchInputPlaceholderRules();
}

byu-search.mobile-view input.__byu-search-selected-input {
  @include searchInputMobile();
}

byu-search {
  input:not(.__byu-search-selected-input), button {
    display: none;
  }
}


/****** FOUC STYLING ******/

byu-search:not(.byu-component-rendered) {
  background: $white;
  width: 216px;
  display: flex;
  justify-content: flex-end;
  order: 2;
  margin: auto 16px;
  height: 27px;
}

byu-search:not(.byu-component-rendered):after {
  @include searchButton();
  float: right;
  height: 27px;
  width: 39px;
  content: url(https://cdn.byu.edu/shared-icons/latest/fontawesome/search-navy.svg);
  padding: 5px 10.5px;
  display: block;
}


/****** RESPONSIVE FOUC STYLING ******/

@media (max-width: 1023px) {

  byu-search:not(.byu-component-rendered) {
    display: none;
  }
}


/***HAMBURGER MENU VERSION
@media (max-width: 1023px) {

  byu-search:not(.byu-component-rendered) {
    width: 100%;
    height: 34px;
    margin: 0;
    border-bottom: 1px solid #c5c5c5;
  }

  byu-search:not(.byu-component-rendered):after {
    height: 33px;
    width: 53px;
    padding: 6.5px 15.5px;
  }
}
***/