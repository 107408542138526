@import "../shared-styles/colors.scss";
@import "../shared-styles/settings.scss";

@mixin byuSearch() {
  //display: inline-block;
  margin-left: 29px;
}

@mixin searchIcon() {
  width: 17px;
  height: 17px;
  display: flex;
}

@mixin searchButton() {
  background-color: $lightgray;
  border: 1px solid $lightgray;
  color: $white;
  width: $searchWidth;
  height: $searchHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  z-index: 1;
}

@mixin searchForm() {
  display: flex;
  align-items: stretch;
  align-content: center;
  width: 216px;
}

@mixin searchContainer() {
  flex: 1;
}

@mixin searchInputPlaceholder() {
  color: $medgray !important;
  opacity: 1;
}

@mixin searchInputPlaceholderRules() {
  //Comma-joining these doesn't work.
   &::placeholder {
    @include searchInputPlaceholder();
  }
  &::-webkit-input-placeholder {
    @include searchInputPlaceholder();
  }
  &:-ms-input-placeholder {
    @include searchInputPlaceholder();
  }
}

@mixin searchInput() {
  padding: 5px 10px;
  border: none;
  // !important needed to override bad CMS styling: #388
  height: $searchHeight!important;
  // Fixes issues on Safari with type=search
  -webkit-appearance: none;
  border-radius: 0;
  margin: 0;
  // END: Safari fixes
  box-sizing: border-box;
  font-family: 'HCo Ringside Narrow SSm', Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: $navy !important;
  width: 100%;
}

@mixin searchMobile() {
  width: 100%;
  height: 35px;
  margin: 0;
}

@mixin searchInputMobile() {
  padding-left: $pageContentHorizontalPadding;
  width: 100%;
  // !important needed to override bad CMS styling: #388
  height: 35px!important;
}

@mixin searchButtonMobile() {
  width: 53px;
  height: 35px;
  padding: 0 16px;
}

@mixin searchIconMobile() {
  width: 20px;
  height: 20px;
  padding-bottom: 6.5px;
  padding-top: 6.5px;
}