@import "../shared-styles/nav.scss";
@import "../shared-styles/colors.scss";

@mixin nonMobileUserImage() {
  background-image: url('https://cdn.byu.edu/shared-icons/latest/fontawesome/user-circle-o-white.svg');
}

@mixin mobileUserImage() {
  background-image: url('https://cdn.byu.edu/shared-icons/latest/fontawesome/user-circle-o-navy.svg');
  margin-left: 0;
}

@mixin userInfoImage() {
  height: 14px;
  width: 14px;
  margin: 5px 11px 5px 5px;
  background-size: contain;
}

@mixin userInfoSlotted() {
  text-decoration: none !important;
  font-size: 11px!important;
  font-family: $headerContentFont !important;
  font-weight: $headerContentFontWeight!important;
  text-transform: uppercase!important;
  color: white !important;
}

@mixin userPresent() {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin centeredText() {
  display: flex;
  align-items: center;
}

/* NON MOBILE */
@mixin userInfoNotMobile() {
  color: #D8D8D8;
}

@mixin userInfoNotMobileDisplay() {
  display: none;
}
@mixin nonMobileUsername() {
  color: $userNameGray !important;
  text-transform: uppercase !important;
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 7px;
}

@mixin nonMobileHeaderLinkHover() {
  text-decoration: underline !important;
}

@mixin userInfoNotMobileImages() {
  margin: 7px;
}

/* MOBILE */
@mixin userInfoMobile() {
  @include dropdownNavItem();
  padding: 10px 14px 10px 25px !important;
  background-color:#EDEDED !important;
  line-height: 12px !important;
  
}

@mixin userInfoMobileNotDisplay() {
  display: none;
}

@mixin hasUserName() {
  order: 2;
  flex: 1;
}

@mixin hasUserNameSlotted() {
  text-transform: uppercase !important
}

/* @mixin hasUserImage() {
  order: 1;
  margin: 0 !important;
  padding: 0 14px 0 0 !important;
} */

/* @mixin noUserImage() {
  margin: 0 !important;
  padding: 0 14px 0 0 !important;
} */

@mixin hasUserLogout() {
  order: 3;
}

@mixin mobileSlotted() {
  color: $navy !important;
  font-size: 10px;
}

@mixin notHasUserMobile() {
  display: none;
}

@mixin noUserMobile() {
  display: none;
}