
@import "../shared-styles/colors";
@import "../shared-styles/settings";

@mixin footerActionButton() {
    background-color: $footerButtonColor;
    font-family: $footerButtonFont;
    font-size: 13px !important;
    color: $white;
    display: inline-block;
    text-align: center;
    line-height: 15px;
    padding: 10px 0;
    width: auto;
    min-width: 125px;
    margin: 20px 0;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(20,20,20,0.35);
    -moz-box-shadow: 0px 3px 5px 0px rgba(20,20,20,0.35);
    box-shadow: 0px 3px 5px 0px rgba(20,20,20,0.35);
    letter-spacing: 0.5px;
}

// Use this to change how the button looks when active
// :host(:active) {
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
// }

@mixin footerActionButtonSlotted() {
    font-family: $footerButtonFont !important;
    font-weight: $footerContentFontWeight !important;
    color: $white !important;
    display: inline-block !important;
    position: relative !important;
    width: 100% !important;
    margin: 0 20px !important;
    left: -20px !important;
    cursor: pointer !important;
    vertical-align: middle !important;
    height: 15px !important;
}

@mixin footerActionButtonSlottedLink() {
    text-decoration: none !important;
}

@mixin footerActionButtonSlottedLinkFocus() {
    outline: solid 2px white !important;
}

@mixin mobileFooterActionButton() {
    padding: 6.5px 0;
    font-size: 11px;
    line-height: 13px;
}
