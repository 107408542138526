@import "../shared-styles/colors.scss";
@import "../shared-styles/settings.scss";

$universityFooterBgColor: $navy;
$universityFooterTextColor: white;
$universityFooterFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$universityFooterFontWeight: 400;
$universityFooterHeight: 80px;

$universityFooterLogoHeight: 40px;
$universityFooterLogoWidth: 390px;
$universityFooterLogoVerticalOffset: 10px;

$universityFooterLogoMobileWidth: 280px;


$universityFooterVerticalPadding: 21px;

$siteFooterHorizontalMargin: 8px;
$siteFooterTopPadding: 0;
$siteFooterBottomPadding: 50px;

$footerHorizontalPadding: 16px;

$footerColumnMargin: 16px $siteFooterHorizontalMargin 0;
$footerColumnPadding: 0 16px;

$large-siteFooterContentTopMargin: 33px;
$large-siteFooterContentBottomMargin: 40px;

$large-siteFooterContentMargin: $large-siteFooterContentTopMargin 8px $large-siteFooterContentBottomMargin;

$siteFooterBackground: $lightgray;
$siteFooterContentColor: $darkgray;
$siteFooterColumnTitleFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$siteFooterContentFont: 'HCo Ringside Narrow SSm', Arial, sans-serif;
$siteFooterContentFontWeight: 400;
$siteFooterContentFontSize: 13px;

