@import "byu-footer-column-common";

/****** FOUC STYLING ******/

byu-footer-column:not(.byu-component-rendered) {
    @include footerColumn();
    margin: 0 8px 40px;
    padding: 0;

    .header {
        @include header();
    }

    .header > * {
        @include headerSlotted();
    }

    .content *:not(byu-footer-action-button) {
        @include contentNotButton();
    }

    #defaultContent a {
        @include defaultContentLink();
    }

    #defaultContent a:hover {
        @include defaultContentLinkHover();
    }

    #defaultContent a:focus {
        @include defaultContentLinkFocus();
    }
}

byu-footer-column:not(.byu-component-rendered) [slot="header"] {
    @include headerSlotted();
    display: block;
    margin-bottom: 10px;
}

byu-footer-column:not(.byu-component-rendered) a {
    @include contentNotButton();
    @include defaultContentLink();
}

byu-footer-column:not(.byu-component-rendered) byu-footer-action-button a {
    color: #fff !important;
}

byu-footer-column:not(.byu-component-rendered) p, byu-footer-column:not(.byu-component-rendered) div {
    @include contentNotButton();
}

byu-footer-column a {
    @include contentNotButton();
    @include defaultContentLink();
}

byu-footer-column a:hover {
    @include defaultContentLinkHover();
}

byu-footer-column a:focus {
    @include defaultContentLinkFocus();
}

@media (max-width: 1024px) {
    byu-footer-column a {
        @include mobileContentLink();
    }
}


/****** RESPONSIVE FOUC STYLING ******/

@media (max-width: 1024px) {

    byu-footer-column:not(.byu-component-rendered) [slot="header"] {
        font-size: 12px !important;
    }

    byu-footer-column:not(.byu-component-rendered) a {
        font-size: 12px !important;
        height: 25px;
    }

    byu-footer-column:not(.byu-component-rendered) p, byu-footer-column:not(.byu-component-rendered) div {
        font-size: 12px !important;
    }

}
